import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
            },
            dark: {
                primary: "#5b942c",
            },
        },
        dark: true },
})
