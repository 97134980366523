<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <h1 class="hidden-sm-and-down">ATV 10Ghz - Monitoring</h1>
        <h1 class="hidden-md-and-up">ATV 10Ghz</h1>
      </div>

      <v-spacer></v-spacer>

      <v-icon :color="updated ? '#00ff00' : 'black'">mdi-circle</v-icon>

    </v-app-bar>

    <v-main>
      <Dashboard/>
    </v-main>

    <v-footer>
      <span class="ml-2 subtitle">
        © DO7LK / DM0MAX, 2023
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import Dashboard from './components/Dashboard';

export default {
  name: 'App',

  components: {
    Dashboard,
  },
  mounted() {
    this.$options.sockets.onmessage = () => {
      this.updated = true;
      setTimeout(() => {
        this.updated = false;
      },200)
    }
  },
  data: () => ({
    updated: 0,
  }),
};
</script>
