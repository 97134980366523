<template>
    <v-container>
      <v-row v-if="!loading" class="text-center">
        <v-col cols="12">
          <v-card>
            <v-card-title class="bottomTitle">Netzteil</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.power.voltage.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="11"
                      :max="15"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Spannung"
                      label-text="V" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.power.current.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="10"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Stromstärke"
                      label-text="A" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.power.temp.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="60"
                      :formatFunction="(x) => parseFloat(x).toFixed(1)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Temperatur"
                      label-text="°C" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.power.voltage.current * device.power.current.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="150"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Leistung"
                      label-text="Watt" />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card :color="device.quadruple.voltage.current <= 50 ? 'red' : ''">
            <v-card-title class="bottomTitle">2550 MHz x 4</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.quadruple.voltage.current > 50 ? 60 : 0"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="150"
                      :formatFunction="(x) => parseFloat(x).toFixed(0)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Leistung"
                      label-text="mW" />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-card-title class="bottomTitle">Treiberstufe</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.amplifier1.voltage.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 145: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="1500"
                      :formatFunction="(x) => parseFloat(x).toFixed(0)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Monitorspannung"
                      label-text="mV" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="device.amplifier1.temp.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="60"
                      :formatFunction="(x) => parseFloat(x).toFixed(1)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Temperatur"
                      label-text="°C" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="getTreiberPowerByVoltage(device.amplifier1.voltage.current/1000)*1000"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="500"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="HF Watt"
                      label-text="mW" />
                </v-col>
                <v-col class="gaugecol">
                  <k-gauge
                      :value="getDbmByPower(getTreiberPowerByVoltage(device.amplifier1.voltage.current/1000))"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="60"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Leistung"
                      label-text="dBm" />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="bottomTitle">Endstufe</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <k-gauge
                      :value="device.amplifier2.current.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="5"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Stromstärke"
                      label-text="A" />
                </v-col>
                <v-col>
                  <k-gauge
                      :value="device.amplifier2.temp.current"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="60"
                      :formatFunction="(x) => parseFloat(x).toFixed(1)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Temperatur"
                      label-text="°C" />
                </v-col>
                <v-col>
                <k-gauge
                    :value="getPAPowerByVoltage(device.amplifier2.voltage.current/1000)"
                    :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                    :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                    :animationDuration="2000"
                    :min="0"
                    :max="15"
                    :formatFunction="(x) => parseFloat(x).toFixed(2)"
                    valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                    labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                    minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                    title="HF Watt"
                    label-text="Watt" />
                </v-col>
                <v-col>
                  <k-gauge
                      :value="getDbmByPower(getPAPowerByVoltage(device.amplifier2.voltage.current/1000))"
                      :width="$vuetify.breakpoint.mdAndDown ? 140: 250"
                      :height="$vuetify.breakpoint.mdAndDown ? 90: 160"
                      :animationDuration="2000"
                      :min="0"
                      :max="60"
                      :formatFunction="(x) => parseFloat(x).toFixed(2)"
                      valueFontStyle="font-size: 35px; fill: #ffffff; font-weight: bold;"
                      labelFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      minMaxFontStyle="font: 1.25rem Arial; fill: #b3b3b3"
                      title="Leistung"
                      label-text="dBm" />
                </v-col>
              </v-row>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>

      <v-dialog
          v-else
          v-model="loading"
          hide-overlay
          persistent
          width="350"
      >
        <v-card
            color="primary"
            dark
        >
          <v-card-text style="text-align: center">

            <v-progress-circular
                indeterminate
                size="70"
                color="white"
                class="ma-6"
            ></v-progress-circular>
            <br>
            <span class="title">Verbindung wird hergestellt...</span>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-container>
</template>

<script>
import KGauge from "@kagronick/kgauge-vue";
  export default {
    name: 'Dashboard',
    components: {
      KGauge
    },
    getters: {
    },
    methods: {
      getSignalLevelDbm: function(voltage) {
        return 10 * Math.log10(((voltage**2) * 1000) / 50);
      },
      getSignalPowerByDbm: function(dbm) {
        return 10**((dbm-30)/10);
      },
      getSignalPowerByVoltage: function(voltage) {
        return this.getSignalPowerByDbm(this.getSignalLevelDbm(voltage));
      },
      getDbmByPower: function(power) {
        return 10 * Math.log10(power) + 30;
      },
      getTreiberPowerByVoltage: function(x) {
        let voltage =  0.2002 * x**2+0.1802 * x;
        voltage = voltage - 0.0483;
        return voltage;
      },
      getPAPowerByVoltage: function(x) {
        const voltage = parseFloat(x);
        let erg = 13.0415 * voltage;
        erg = erg - 1.5654;
        return erg;
      }
    },
    mounted() {
      this.$options.sockets.onmessage = (response) => {
        if(this.loading) this.loading = false;
        const data = JSON.parse(response.data);
        if(data.response === "get"){
          // power
          this.device.power.voltage.current = data.value.NT;
          this.device.power.current.current = data.value.S1;
          this.device.power.temp.current = data.value.T0;
          // quadruple
          this.device.quadruple.voltage.current = data.value.X4;
          // amplifier
          this.device.amplifier1.voltage.current = data.value.PA1*3/2; // Zweifel an Spannungsteiler, Werte sind wahrscheinlich mal 3 11.01.23 DO9BM
          this.device.amplifier1.temp.current = data.value.T1;
          // amplifier2
          this.device.amplifier2.voltage.current = data.value.PA2;
          this.device.amplifier2.current.current = data.value.S2;
          this.device.amplifier2.temp.current = data.value.T2;

          // Treiberstufenwert muss korrigiert werden:
          // Wenn jetzt die grosse PA zugeschaltet wird, reflektiert da was zurück. Also SWR nicht optimal. Das adiert der
          if(this.device.amplifier2.current.current > 1){
            // Endstufe ist an
            this.device.amplifier1.current.current = this.device.amplifier1.current.current * 0.8; //
          }
        }
      };
    },
    data: () => ({
      lastUpdate: null,
      loading: true,
      device: {
        power: {
          voltage: {
            current: null,
            series: []
          },
          current: {
            current: null,
            series: []
          },
          temp: {
            current: null,
            series: []
          },
        },
        quadruple: {
          voltage: {
            current: null,
            series: []
          },
        },
        amplifier1: {
          voltage: {
            current: null,
            series: []
          },
          temp: {
            current: null,
            series: []
          },
        },
        amplifier2: {
          voltage: {
            current: null,
            series: []
          },
          current: {
            current: null,
            series: []
          },
          temp: {
            current: null,
            series: []
          },
        }
      },
    }),
  }
</script>

<style>
.bottomTitle {
  position: absolute;
  bottom: -23px;
  font-size: 1.1rem;
}


.gaugecol{
  padding: 5px;
}
</style>
